



































































































































































































import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import { trendCompare } from '@/utils/echarts/echarts';
import countUpFn from '@/utils/countUp';
import { CustomerParamsStartEnd } from '@/types/store';
import { passengerFlowData } from '@/services/api';
import spinCard from '@/components/SpinCard/index.vue';
import { getMallServiceStartAndEnd } from '@/services/systemApi';
import {
  initDate,
  computePassengerFlowProfile,
  getDateAryByStartEnd,
  workDate,
  weekDate,
  getCompareWeekDate,
} from '@/components/index';
/* eslint-disable @typescript-eslint/naming-convention */
import tdesignFooter from '@/components/GlobalFooter/index.vue';
@Component({
  components: {
    compareCard,
    tdesignFooter,
    spinCard,
  },
})
export default class SummaryCompare extends Vue {
  // countUpFn: any;
  prefix: string = prefix;
  comparedatas = [];
  comparedatas2 = [];
  slotcontent = true;
  line = true;
  slotEcharts = true;
  echarts1Show = true;
  echarts2Show = true;
  sourceType = 1;
  sourceType2 = 1;
  flow1 = 0;
  flow2 = 0;
  newNum1 = 0;
  newNum2 = 0;
  flowRatio: any = 0;
  newNumRatio: any = 0;
  initStatus = 1;
  trendCompare1 = {};
  trendCompare2 = {};
  duration = this.$store.state.mall.duration;
  mallId = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  list = [
    {
      value: 1,
      label: '全部',
    },
    {
      value: 2,
      label: '工作日',
    },
    {
      value: 3,
      label: '周末',
    },
  ];
  $dayjs: any;
  date1Ary: string[] = [];
  date2Ary: string[] = [];
  yesterWeekDay1 = this.date;
  yesterWeekStartDay2 = this.date;
  yesterWeekEndDay2 = this.date;
  time1Obj = {
    start: this.date,
    end: this.date,
  };
  time2Obj = {
    start: this.date,
    end: this.date,
  };
  time1Value = [this.time1Obj.start, this.time1Obj.end];
  time2Value = [this.time2Obj.start, this.time2Obj.end];
  rtime1Obj = this.time1Obj;
  rtime2Obj = this.time2Obj;
  rMallIds = this.mallId;
  services = this.$stores.mall.services;
  dateOptions = {
    disabledDate: (date: Date): number => {
      if (this.$stores.mall.services.mallId !== this.services.mallId) {
        this.services =  this.$stores.mall.services;
      }
      const dates = this.$dayjs(date).format('YYYYMMDD');
      const maxDate = this.$dayjs(initDate('d')).format('YYYYMMDD');
      let isChecked = 1; // 1: 禁用 0: 正常
      // 大于最新日期，直接禁用
      if (dates > maxDate) {
        return isChecked;
      }
      // 禁用掉非服务期间数据
      if (this.services.mallId) {
        for (const item of this.services.dates) {
          if (dates >= item.serviceStart && dates <= item.serviceEnd) {
            isChecked = 0;
            return isChecked;
          }
        }
      }
      return isChecked;
    },
    firstDayOfWeek: 1,
  };

  $beaconAction: any;
  destroy(): void{
    (this.$el.parentNode as any).removeChild(this.$el);
    this.$destroy();
  }
  async mounted() {
    this.date = this.$store.state.mall.date;
    await this.setMallServices();
    await this.getWeekDates();
    this.inquireClickHanlder();
  }

  warning(text: string): void{
    this.$Message.warning(text);
  }

  async getWeekDates() {
    const item = this.services?.dates[this.services.dates.length - 1];
    if (this.services.mallId && item.days < 14) {
      const serviceStart = (this as any).$dayjs(item.serviceStart)
        .format('YYYY-MM-DD');
      let serviceEnd = (this as any).$dayjs(item.serviceEnd);
      const nowDate = (this as any).$dayjs().subtract(1, 'day');
      if (nowDate.isBefore(serviceEnd)) {
        serviceEnd = nowDate;
      }
      serviceEnd = serviceEnd.format('YYYY-MM-DD');
      this.time1Obj = {
        start: serviceStart,
        end: serviceEnd,
      };
      this.time2Obj = {
        start: serviceStart,
        end: serviceEnd,
      };
      this.time1Value = [serviceStart, serviceEnd];
      this.time2Value = [serviceStart, serviceEnd];
    } else {
      this.yesterWeekDay1 = getCompareWeekDate(this.date);
      this.yesterWeekEndDay2 = (this as any).$dayjs(this.yesterWeekDay1).subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.yesterWeekStartDay2 = getCompareWeekDate(this.yesterWeekEndDay2);
      this.time1Obj = {
        start: this.yesterWeekDay1,
        end: this.date,
      };
      this.time2Obj = {
        start: this.yesterWeekStartDay2,
        end: this.yesterWeekEndDay2,
      };
      this.time1Value = [this.time1Obj.start, this.time1Obj.end];
      this.time2Value = [this.time2Obj.start, this.time2Obj.end];
    }
  }

  async setMallServices() {
    if (this.services.mallId !== this.mallId) {
      const res: any = await getMallServiceStartAndEnd({ mall_id: this.mallId });
      if (res.status === 0) {
        this.services = {
          dates: [],
          mallId: this.mallId,
          isTestUser: false,
        };
        let sum = 0;
        for (const item of res.data) {
          const { service_cycle_start, service_cycle_end } = item;
          const start = new Date(service_cycle_start).getTime();
          const end = new Date(service_cycle_end).getTime();
          const s = (end - start) / 1000 / 60 / 60 / 24;
          this.services.dates.push({
            serviceStart: this.$dayjs(service_cycle_start).format('YYYYMMDD'),
            serviceEnd: this.$dayjs(service_cycle_end).format('YYYYMMDD'),
            days: s,
          });
          sum += s;
        }
        if (sum < 15) {
          this.services.isTestUser = true;
        }
        // 切换了mall需要判断勾选的时间是否还在服务期，不在服务期间的话默认给到当前时间
        this.$stores.mall.setMallServices(this.services);
      }
    }
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.echarts1Show = true;
    this.echarts2Show = true;
    this.mallId = this.$store.state.mall.mallId;
    this.duration = 'd';
    const data1: any = await this.getData(this.date1Ary);
    this.comparedatas = data1;
    const data2: any = await this.getData(this.date2Ary);
    this.comparedatas2 = data2;
    const times1 = data1.times;
    const flowSeriesData1 = data1.flows;
    const numSeriesData1 = data1.newNums;
    const times2 = data2.times;
    const numSeriesData2 = data2.newNums;
    const flowSeriesData2 = data2.flows;
    const data1Obj = data1.dataObj;
    const data2Obj = data2.dataObj;
    const sumObj1 = data1.sumObj;
    const sumObj2 = data2.sumObj;
    const { flow: flow1, new_num: newNum1 } = sumObj1;
    const { flow: flow2, new_num: newNum2 } = sumObj2;
    const flow1Lth = flowSeriesData1.length;
    const flow2Lth = flowSeriesData2.length;
    if (flow1Lth < flow2Lth) {
      const lth = flow2Lth - flow1Lth;
      for (let index = 0; index < lth; index++) {
        flowSeriesData1.push('');
      }
    } else if (flow1Lth > flow2Lth) {
      const lth = flow1Lth - flow2Lth;
      for (let index = 0; index < lth; index++) {
        flowSeriesData2.push('');
      }
    }
    const nNum1Lth = numSeriesData1.length;
    const nNum2Lth = numSeriesData2.length;
    if (nNum1Lth < nNum2Lth) {
      const lth = nNum2Lth - nNum1Lth;
      for (let index = 0; index < lth; index++) {
        numSeriesData1.push('');
        times1.push('');
      }
    } else if (nNum1Lth > nNum2Lth) {
      const lth = nNum1Lth - nNum2Lth;
      for (let index = 0; index < lth; index++) {
        numSeriesData2.push('');
        times2.push('');
      }
    }
    this.trendCompare1 = trendCompare(
      times1,
      times2,
      flowSeriesData1,
      flowSeriesData2,
      data1Obj,
      data2Obj,
    );
    this.trendCompare2 = trendCompare(
      times1,
      times2,
      numSeriesData1,
      numSeriesData2,
      data1Obj,
      data2Obj,
    );
    this.flow1 = flow1;
    this.flow2 = flow2;
    this.newNum1 = newNum1;
    this.newNum2 = newNum2;
    countUpFn('box1', this.flow1);
    countUpFn('box2', this.flow2);
    countUpFn('box3', this.newNum1);
    countUpFn('box4', this.newNum2);
    this.flowRatio = computePassengerFlowProfile(
      String(this.flow1),
      String(this.flow2),
      3,
    );
    this.newNumRatio = computePassengerFlowProfile(
      String(this.newNum1),
      String(this.newNum2),
      3,
    );
    this.echarts1Show = false;
    this.echarts2Show = false;
    // this.show = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  async getData(dateAry: string[]): Promise<void> {
    const params: CustomerParamsStartEnd = {
      duration: this.duration,
      mall_id: this.mallId,
      date_ary: dateAry,
      date_start: dateAry[0],
      date_end: dateAry[dateAry.length - 1],
    };
    const datas = await passengerFlowData(params);
    return datas;
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  time1ChangeHandler(dateAry: string[]): void {
    const start = this.$dayjs(dateAry[0]).format('YYYY-MM-DD');
    const end = this.$dayjs(dateAry[1]).format('YYYY-MM-DD');
    this.time1Obj = {
      start,
      end,
    };
    this.time1Value = [this.time1Obj.start, this.time1Obj.end];
    this.buriedPointHandler('Passenger_flow_comparison_time1_click');
  }

  time2ChangeHandler(dateAry: string[]): void {
    const start = this.$dayjs(dateAry[0]).format('YYYY-MM-DD');
    const end = this.$dayjs(dateAry[1]).format('YYYY-MM-DD');
    this.time2Obj = {
      start,
      end,
    };
    this.time2Value = [this.time2Obj.start, this.time2Obj.end];
    this.buriedPointHandler('Passenger_flow_comparison_time2_click');
  }

  typeChangeHandler(value: number): void {
    this.sourceType = value;
    this.buriedPointHandler('Passenger_flow_comparison_type_click');
  }
  inquireClickHanlder(): void {
    if (this.initStatus === 0) {
      this.initStatus = 1;
    }
    this.buriedPointHandler('Passenger_flow_comparison_search_click');
    // 防止多次点击
    const start1 = this.time1Obj.start;
    const end1 = this.time1Obj.end;
    const start2 = this.time2Obj.start;
    const end2 = this.time2Obj.end;

    if (start1 === this.rtime1Obj.start
    && end1 === this.rtime1Obj.end
    && start2 === this.rtime2Obj.start
    && end2 === this.rtime2Obj.end
    && this.sourceType === this.sourceType2
    && this.rMallIds === this.mallId
    ) {
      const text = '参数相同，请勿重复查询';
      this.warning(text);
      return;
    }
    this.rMallIds = this.mallId;
    this.rtime1Obj = {
      start: start1,
      end: end1,
    };
    this.rtime2Obj = {
      start: start2,
      end: end2,
    };
    this.sourceType2 = this.sourceType;

    let date1Ary: string[] = [];
    let date2Ary: string[] = [];
    let lth1 = 0;
    let lth2 = 0;
    date1Ary = getDateAryByStartEnd(start1, end1);
    date2Ary = getDateAryByStartEnd(start2, end2);
    if (this.sourceType === 2) {
      date1Ary = workDate(date1Ary);
      date2Ary = workDate(date2Ary);
    } else if (this.sourceType === 3) {
      date1Ary = weekDate(date1Ary);
      date2Ary = weekDate(date2Ary);
    }
    lth1 = date1Ary.length;
    lth2 = date2Ary.length;
    if (lth1 > 90 || lth2 > 90) {
      // alert('时间跨度不可大于90天');
      const text = '时间跨度不可大于90天';
      this.warning(text);
      return;
    }
    this.date1Ary = date1Ary;
    this.date2Ary = date2Ary;
    this.pageHandler();
  }

  resetClickHanlder(): void{
    this.sourceType = 1;
    this.getWeekDates();
    this.inquireClickHanlder();
  }

  // @Watch('$store.state.mall', { deep: true })
  // changeMall(): void{
  //   this.mallId = this.$store.state.mall.mallId;
  // }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void{
    this.pageHandler();
  }

  // @Watch('$store.state.global.downloadStatus')
  // changeExport(): void{
  // }
  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const status = this.$store.state.global.downloadStatus;
    const datas = [];
    const arr1: any[] = [];
    const arr2: any[] = [];
    const arr3: any[] = [];
    const arr4: any[] = [];
    Object.keys((this.comparedatas as any).data).forEach((i: any) => {
      arr1.push((this.comparedatas as any).data[i]);
    });
    Object.keys((this.comparedatas2 as any).data).forEach((i: any) => {
      arr2.push((this.comparedatas2 as any).data[i]);
    });
    arr3.push({
      time: `${(this.comparedatas as any).dates[0]}~${
        (this.comparedatas as any).dates[
          (this.comparedatas as any).dates.length - 1
        ]
      }`,
      flow: (this.comparedatas as any).sumObj.flow,
      num: (this.comparedatas as any).sumObj.new_num,
    });
    arr4.push({
      time: `${(this.comparedatas2 as any).dates[0]}~${
        (this.comparedatas2 as any).dates[
          (this.comparedatas2 as any).dates.length - 1
        ]
      }`,
      flow: (this.comparedatas2 as any).sumObj.flow,
      num: (this.comparedatas2 as any).sumObj.new_num,
    });
    if (status === true) {
      datas.push({
        sheetData: arr1,
        sheetName: '时间段1客流对比',
        sheetHeader: ['时间段1日期', '人次', '人数'],
        sheetMultiHeader: [
          ['数据项:客流对比'],
        ],
        sheetFilter: ['time', 'flow', 'num'],
      });
      datas.push({
        sheetData: arr2,
        sheetName: '时间段2客流对比',
        sheetHeader: ['时间段2日期', '人次', '人数'],
        sheetMultiHeader: [
          ['数据项:客流对比'],
        ],
        sheetFilter: ['time', 'flow', 'num'],
      });
      datas.push({
        sheetData: arr3,
        sheetName: '时间段1总数',
        sheetHeader: ['时间段2日期', '总客流人次', '总新客流人数'],
        sheetMultiHeader: [
          ['数据项:客流对比'],
        ],
        sheetFilter: ['time', 'flow', 'num'],
      });
      datas.push({
        sheetData: arr4,
        sheetName: '时间段2总数',
        sheetHeader: ['时间段2日期', '总客流人次', '总新客流人数'],
        sheetMultiHeader: [
          ['数据项:客流对比'],
        ],
        sheetFilter: ['time', 'flow', 'num'],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
}
