
import { CountUp } from 'countup.js';
export default function countUpFn(id: string, countUpVal: number): any {
  const options = {
    useEasing: true,
    useGrouping: true,
    separator: ',',
    decimal: '.',
  };
  const demo = new CountUp(id, countUpVal, options);
  if (!demo.error) {
    return demo.start();
  }
}
